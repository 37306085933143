/* Services: research engineering
 *
 */

import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import Tagcloud from "../../components/Tagcloud"
import Calendly from '../../components/Calendly'
import InquiryLink  from '../../components/InquiryLink'
import ServicesArticles from '../../components/ServicesArticles'

import fakenet  from './fakenet.png'
import styles from './research-engineering.module.styl'

const title = 'FELD Berlin. We build data products that work for your business'
const description = `
  Need a recommender or a better search system? Hire us to get your products
  flying. We work with you to understand your product goals and to define
  effective metrics. We will choose the best suited ML stack to meet your
  requirements in a performant, maintanable and ethical way. We know the
  research – but we also know how to work together with your development, UX
  and product teams in an agile manner. <span>Together, we can build data
  products that really work for your business.</span>
`

export default function ResearchEngineering(props) {
  const posts = props.data.allMdx.edges

  return (
      <Layout location={ props.location } footer={ "newsletter" }>
      <div className={ styles.researchEngineering }>
        <SEO title={ title } description={ description } />
        <h1>Data Science and Research Engineering</h1>
        <p dangerouslySetInnerHTML={{ __html: description }} />
        <img src={ fakenet } />
        <div className={ styles.service }>
          <h2>Deep Learning</h2>
          <p>
            We have years of experience in getting deep learning systems deployed into
            production. Whether your stack is tensorflow or pytorch, GCP or AWS, we
            will select the simplest models that can solve your problem well. We’ll
            iterate on new or existing models in a principled and reproducible way,
            and keep you in the loop all the way through the process.
          </p>
        </div>
        <div className={ styles.service }>
          <h2>Recommender Systems</h2>
          <p>
            We have designed and bulit some of the biggest recommendation engines on the
            internet. Let us help you to understand if you can improve your sales or
            product with a better recommendation engine. We will analyse your product,
            provide you with a roadmap, and follow through with an implementation that
            might just provide a surprising uplift to your key metrics.
          </p>
        </div>
        <div className={ styles.service }>
          <h2>Unstructured Data</h2>
          <p>
            Are you processing emails or would you like to deploy a chatbot? We have
            extensive experience with natural language. Do you have valuable data
            locked away in speech or audio? We can apply speech or music information
            retrieval techniquies. Do you need extract structured information from
            pdfs, or metadata from video? We have you covered.
          </p>
        </div>
        <div className={ styles.service }>
          <h2>Search</h2>
          <p>
            Getting the most out of your search system is difficult. Many users navigate
            your product via search, while expecting google level quality from your
            results. Let us help you with your entire search pipeline. We will look at
            retrieval, ranking and query understanding, measure your search
            performance and design and implement methods to really improve the search
            experience for your users.
          </p>
        </div>
        <div className={ styles.footer }>
          <div className={ styles.tags }>
            <Tagcloud tags={ [
              'ab testing',
              'churn prediction',
              'customer segmentation',
              'deep learning',
              'factorisation machines',
              'fraud detection',
              'generative models',
              'information retrieval',
              'learning to rank',
              'natural language',
              'pytorch',
              'recommender systems',
              'search',
              'tensorflow',
            ] } />
          </div>
          <div className={ styles.callToAction }>
            <div className={ styles.questions }>
              Learn more about how we can help with your <br />
              research engineering:
            </div>
            <Calendly text="Schedule a call"/>
            <InquiryLink />
          </div>
          <div className={ styles.divider } />
          <ServicesArticles articles={ posts } />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { category: { eq: "research engineering" },
      published: { eq: true }}}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            author
            kicker
            intro
            category
          }
        }
      }
    }
  }
`
